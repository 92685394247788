<template>
  <div class="password">
    <div class="wrapper">
      <div class="title font-md">
        <span>비밀번호 재설정</span>
      </div>
      <div class="desc font-sm">새로운 비밀번호를 입력해주세요.</div>
      <div class="form">
        <input :id="`${component.name}NewPassword`" type="password" class="form-control border-focus-point" v-model="state.form.newPasswd" placeholder="새로운 비밀번호를 입력해주세요." @keyup.enter="submit()"/>
        <input :id="`${component.name}NewPasswordRepeat`" type="password" class="form-control border-focus-point" placeholder="새로운 비밀번호를 한번 더 입력해주세요." @keyup.enter="submit()"/>
      </div>
    </div>
    <div class="actions">
      <div class="row">
        <div class="col">
          <button class="btn btn-point btn-block font-sm" @click="submit()">재설정하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import Phone from "@/components/Phone";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "modalPassword";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Phone},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});
    });

    const state = reactive({
      form: {
        memberId: "",
        oldPasswd: "",
        newPasswd: ""
      }
    });

    const modalParams = store.getters.modalParams(component);

    const submit = () => {
      const args = lib.getRenewed(state.form);
      const $newPasswordRepeat = document.getElementById(`${component.name}NewPasswordRepeat`);

      if (!args.newPasswd) {
        document.getElementById(`${component.name}NewPassword`).focus();
        return store.commit("setSwingMessage", "새로운 비밀번호를 입력해주세요.");
      } else if (args.newPasswd !== $newPasswordRepeat.value) {
        $newPasswordRepeat.focus();
        return store.commit("setSwingMessage", "두 개의 비밀번호 값을 동일하게 입력해주세요.");
      }

      args.memberId = modalParams.memberId;
      args.oldPasswd = modalParams.tmpPassword;

      http.patch("/api/member/login/find/reset-pw", args).then(({data}) => {
        store.commit("setSwingMessage", data.message);
        store.commit("closeModal", {name: component.name});
      }).catch(httpError());
    };

    return {component, state, submit};
  },
});
</script>

<style lang="scss" scoped>
.password {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
    }

    .form {
      input {
        display: block;
        margin: $px13 0;
        height: $formHeightLg;
        font-size: $px14;
        border-color: #ddd;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .actions {
    .row {
      > div {
        button {
          padding: $px15;
          height: $px56;
          border-radius: 0;
        }

        &.left {
          padding-right: 0;
        }

        &.right {
          padding-left: 0;
        }
      }
    }
  }
}
</style>